@import '../../assets/sass/1-helpers/variables';
%align-items {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1rem;
}
.bag-item-row {
  border-bottom: 1px solid rgba($color: $rest-border, $alpha: 0.65);
  margin-top: 10px;
  padding-bottom: 10px;
  .item-close-btn-wrapper {
    @extend %align-items;
    i {
      color: #c4c4c4;
      font-size: 16px;
    }
  }
  .quantity {
    @extend %align-items;
    font-weight: bold;
  }
  .bag-item {
    @extend %align-items;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 5px;
    .item {
      font-size: 1rem;
      font-weight: 500;
      color: rgb(25, 25, 25);
    }
    .extra-item {
      font-size: 0.875rem;
      color: rgb(118, 118, 118);
      font-weight: 500;
      display: block;
    }
  }
  .bag-operation {
    border: 1px solid $primary-color;
    border-radius: 30px;
    padding: 15px 10px;
    @extend %align-items;
    justify-content: space-evenly;
    height: 60%;
    span {
      color: $primary-color;
      cursor: pointer;
    }
    span:nth-child(2) {
      color: $third-color;
    }
  }
  .bag-price {
    @extend %align-items;
    .extra-item-price {
      font-size: 0.875rem;
    }
  }
}

@media screen and (max-width: 575px) {
  %align-items {
    font-size: 0.875rem;
  }
  .bag-item-row {
    .item-close-btn-wrapper {
      i {
        font-size: 1.125rem;
      }
    }
    .bag-operation {
      .operation {
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}
