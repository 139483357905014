#fixed-social {
    position: fixed;
    top: 30%;
    z-index: 1;
 }
 
 #fixed-social a {
    background-color: #EF8657;
    color: #fff;
    display: block;
    height: 40px;
    position: relative;
    text-align: center;
     line-height: 40px;
    width: 40px;
    margin-bottom: 1px;
    z-index: 2;
 }
 #fixed-social a:hover>span{
     visibility: visible;
   left: 41px;
   opacity: 1;
 } 
 #fixed-social a span {
    background-color: #EF8657;
     line-height: 40px;
     left: 60px;
     position: absolute;
     text-align: center;
     width: 120px;
     visibility: hidden;
     transition-duration: 0.5s;
     z-index: 1;
     opacity: 0;
 }
 

 @media screen and (max-width:1024px){
    #fixed-social {
       display: none;
    }
 }