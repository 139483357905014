@import '../../../../assets//sass/1-helpers/variables';

.menu-card-col {
  .dayofweek {
    font-weight: bold;
  }

  .start-with-end-date {
    font-weight: bold;
    visibility: hidden;
  }

  .menu-card-cont {
    cursor: pointer;
    margin-top: 8px;

    #product-pic-cont {
      width: 100%;
      height: 250px;

      #menu-card-img {
        height: 100%;
        max-width: 100%;
        display: block;
        margin: auto;
        object-fit: cover;
      }
    }

    .right-content {
      padding: 5px 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      span {
        font-size: 0.875rem;
        font-weight: bold;
      }

      h3 {
        font-size: 1.2rem;
        font-weight: bold;
      }

      p {
        font-size: 0.875rem;
        font-weight: 300;
        width: 90%;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
      }

      .choose-btn {
        background-color: #f4895b;
        padding: 5px 15px;
        color: $secondary-color;
        border: none;
        border-radius: 30px;
        width: 40%;
        margin-left: auto;
        text-align: center;
      }

      .sold-out-text {
        transform: translate(-50%, -50%);
        color: white;
        background-color: #bc0405;
        font-weight: bold;
        padding: 2px 5px;
        border-radius: 5px;
        -ms-transform: rotate(120deg);
        /* IE 9 */
        transform: rotate(-35deg);
        width: 30%;
        text-align: center;
        margin-left: auto;
        position: absolute;
        right: -25px;
        bottom: 5px;
      }
    }
  }
}

@media screen and (max-width: 426px) {
  .menu-card-col {
    .menu-card-cont {
      #product-pic-cont {
        height: 180px;
      }

      .right-content {
        h3 {
          font-size: 1rem;
          font-weight: 700;
        }

        p {
          font-size: 0.875rem;
          font-weight: 300;
          width: 90%;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }

        .choose-btn {
          width: 80%;
        }

        .sold-out-text {
          -ms-transform: rotate(-35deg);
          /* IE 9 */
          transform: rotate(-35deg);
          right: -15px;
          bottom: 5px;
          width: 50%;
        }
      }
    }
  }
}