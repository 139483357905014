$primary-color: rgb(74, 74, 74);
$shadow-color: #c4b6b6;
.rest-registration-cont {
  margin: 30px 0;
  border-radius: 30px;
  padding: 24px 0;
  -webkit-box-shadow: 0 0 10px $shadow-color;
  box-shadow: 0 0 10px $shadow-color;
  h6 {
    color: $primary-color;
  }
  p {
    color: #b8c0c0;
  }
  .title-cont {
    h1 {
      font-size: 1.3125rem;
      color: $primary-color;
    }
  }
}
