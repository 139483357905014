@import '../../assets/sass/1-helpers/variables';

.not-found-cont {
  display: flex;
  justify-content: center;
  padding-top: 200px;
  .main {
    min-height: 300px;
    width: auto;
    max-width: 460px;
    display: flex;
    align-items: center;
    justify-content: center;
    .fundo {
      animation: scales 3s alternate infinite;
      transform-origin: center;
    }
    .pao-baixo {
      animation: rotatepao 14s cubic-bezier(0.1, 0.49, 0.41, 0.97) infinite;
      transform-origin: center;
    }
    .pao-cima {
      animation: rotatepao 7s 1s cubic-bezier(0.1, 0.49, 0.41, 0.97) infinite;
      transform-origin: center;
    }
    .olhos {
      animation: olhos 2s alternate infinite;
      transform-origin: center;
    }

    .left-sparks {
      animation: left-sparks 4s alternate infinite;
      transform-origin: 150px 156px;
    }

    .right-sparks {
      animation: left-sparks 4s alternate infinite;
      transform-origin: 310px 150px;
    }

    .olhos {
      animation: olhos 2s alternate infinite;
      transform-origin: center;
    }
    @keyframes scales {
      from {
        transform: scale(0.98);
      }
      to {
        transform: scale(1);
      }
    }

    @keyframes rotatepao {
      0% {
        transform: rotate(0deg);
      }
      50%,
      60% {
        transform: rotate(-20deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }

    @keyframes olhos {
      0% {
        transform: rotateX(0deg);
      }
      100% {
        transform: rotateX(30deg);
      }
    }

    @keyframes left-sparks {
      0% {
        opacity: 0;
      }
    }

    .path {
      stroke-dasharray: 300;
      stroke-dashoffset: 300;
      animation: dash 4s alternate infinite;
    }

    @keyframes dash {
      0%,
      30% {
        fill: 4b4b62;
        stroke-dashoffset: 0;
      }
      80%,
      100% {
        fill: transparent;
        stroke-dashoffset: -200;
      }
    }
  }
  .go-home-btn-cont {
    width: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    max-width: 460px;
    p {
      font-weight: 600;
      margin-bottom: 35px;
    }

    .go-home-btn {
      z-index: 1;
      overflow: hidden;
      background: transparent;
      position: relative;
      padding: 8px 50px;
      border-radius: 30px;
      cursor: pointer;
      font-size: 1em;
      letter-spacing: 2px;
      transition: 0.2s ease;
      font-weight: 600;
      margin: 5px 0px;
      &.green {
        border: 4px solid $primary-color;
        color: #0e0620;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 0%;
          height: 100%;
          background: $primary-color;
          z-index: -1;
          transition: 0.2s ease;
        }
        &:hover {
          color: $secondary-color;
          background: $primary-color;
          transition: 0.2s ease;
          &:before {
            width: 100%;
          }
        }
      }
    }
  }
}


@media screen and (max-width:768px){
  .not-found-cont {
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    .main {
      max-width: 300px;
    }
    .go-home-btn-cont {
      max-width: 300px;
      padding: 0 8px;
    }
  }
}