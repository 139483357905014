.single-rest-cont {
  .main-card-cont {
    padding: 48px 0;
    .menu-card-row {
      margin: 0;
    }
  }
}
.menu-alert {
  .alert-header {
    color: red !important;
  }
}
@media screen and (max-width: 400px) {
  .single-rest-cont {
    .main-card-cont {
      padding: 12px 0;
    }
  }
}
