@import '../../assets/sass/1-helpers/variables';
.account-page-cont {
  padding-top: 24px;
  .left {
    .far {
      color: gray;
    }
    .fas {
      color: gray;
    }
    .nav-pills {
      width: 275px;
      max-width: 100%;
      height: auto;
      background-color: #fff;
      border-radius: 5px;
      -webkit-box-shadow: 0 0 25px 1px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0 0 25px 1px rgba(0, 0, 0, 0.05);
      box-shadow: 0 0 25px 1px rgba(0, 0, 0, 0.05);
      position: relative;
      .nav-link {
        color: #6c757d;
        background-color: transparent;
        border-color: transparent;
      }
      .nav-link.active {
        border-left: 6px solid #73c538;
        background-color: #f6f6f6 !important;
        font-size: 14px;
        font-weight: 800;
        color: #73c538 !important;
      }
      li:nth-child(1) {
        border-radius: 5px 5px 0 0;
      }
      .nav-item {
        font-size: 14px;
        font-weight: 500;
        color: #062819;
        border-bottom: 1px solid #e1e8ee;
        cursor: pointer;
        .nav-link {
          padding: 20px 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 426px) {
  .account-page-cont {
    .left {
      .nav-pills {
        width: 100%;
      }
    }
  }
}
