@import '../../../assets/sass/1-helpers/variables';

.tempvillage-cont {
  .restaurant-detail-row {
    .jumbotron-logo {
      position: relative;
      margin-top: 20px;

      .header-jumbotron {
        display: block;
        z-index: 1;
        object-fit: cover;
        width: 100%;
        height: 280px;
        inset: 0px;
        transform: translateZ(0px);
        border-radius: 5px;
        position: relative;
        top: 0;
        left: 0;
      }

      .logo-container {
        z-index: 2;
        width: 80px;
        height: 80px;
        position: absolute;
        left: 5%;
        bottom: -40px;
        border-radius: 50%;
        overflow: hidden;
        background-color: rgb(255, 255, 255);
        border: 2px solid rgb(255, 255, 255);
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 20%);
        //box-shadow: inset 0 -3px 3px #000;
        box-sizing: border-box;

        .logo {
          width: 76px;
        }
      }
    }

    .restaurant-details {
      margin-top: 60px;

      .restaurant-name {
        font-size: 40px;
        font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
          'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
        font-weight: 700;
        line-height: 48px;
        letter-spacing: -0.04ch;
        text-transform: none;
        color: rgb(25, 25, 25);
        margin: 0px;
        padding: 0px;
        display: block;
        font-variant-ligatures: no-common-ligatures;
      }

      .description {
        font-size: 14px;
        font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
          'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0ch;
        text-transform: none;
        color: rgb(118, 118, 118);
        margin: 4px 0px 0px 0px;
        padding: 0px;
        font-variant-ligatures: no-common-ligatures;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .social-icons-cont {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }

    .info {
      margin-top: 50px;

      .calufe-open {
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        height: 52px;
        width: 175px;
        border-radius: 8px;
        background-color: $successfullcheck;
        color: white;
        font-weight: 700;
      }

      .calufe-closed {
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        height: 52px;
        width: 175px;
        border-radius: 8px;
        background-color: #bf0000;
        color: white;
        font-weight: 700;
      }

      .pickup-btn {
        .pickup-span {
          padding: 5px 10px;
          background-color: black;
          color: rgb(255, 255, 255);
          border-radius: 15px;
          font-weight: 700;
          display: inline;
          position: relative;

          .triangle {
            position: absolute;
            bottom: -32px;
            right: 5px;
            height: 0px;
            width: 0px;
            border-left: 32px solid transparent;
            border-right: 32px solid transparent;
            border-bottom: 32px solid rgb(255, 240, 237);
          }
        }
      }

      .pickup-address-cont {
        margin-top: 20px;

        .sub-cont {
          background-color: rgb(255, 240, 237);
          text-align: center;
          padding: 24px;

          .pickup-address-desc {
            font-size: 18px;
            font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
              'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0ch;
            text-transform: none;
            color: rgb(25, 25, 25);
            margin: 0px;
            padding: 0px;
            display: block;
            font-variant-ligatures: no-common-ligatures;
          }

          .pickup-address {
            font-size: 14px;
            font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
              'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0ch;
            text-transform: none;
            color: rgb(25, 25, 25);
            margin: 0px;
            padding: 0px;
            display: block;
            font-variant-ligatures: no-common-ligatures;
            margin-top: 16px;
          }
        }
      }
    }

    .full-menu-row {
      .full-menu {
        font-size: 16px;
        font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
          'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0ch;
        text-transform: none;
        color: rgb(25, 25, 25);
        margin: 0px;
        padding: 0px;
        display: block;
        font-variant-ligatures: no-common-ligatures;
      }

      .rest-openin-time {
        font-size: 14px;
        font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
          'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0ch;
        text-transform: none;
        color: rgb(118, 118, 118);
        margin: 0px;
        padding: 0px;
        display: block;
        font-variant-ligatures: no-common-ligatures;
      }
    }
  }

  #inclusive-section {
    padding-bottom: 20px;

    .menus-section {
      .category-info-cont {
        .category-header-cont {
          margin: 32px 0px 16px;

          h2 {
            font-size: 22px;
            font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
              'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
            font-weight: 700;
            line-height: 30px;
            letter-spacing: -0.04ch;
            text-transform: none;
            color: rgb(25, 25, 25);
            margin: 0px;
            padding: 0px;
            display: block;
            font-variant-ligatures: no-common-ligatures;
          }

          h3 {
            font-size: 14px;
            font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
              'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0ch;
            text-transform: none;
            color: rgb(118, 118, 118);
            margin: 0px;
            padding: 0px;
            display: block;
            font-variant-ligatures: no-common-ligatures;
          }
        }
      }
    }
  }

}

// horizontal menu CSS Files
.horizontal-menu-row {
  background-color: rgb(255, 255, 255);
  position: sticky;
  top: 80px;
  z-index: 98;

  .horizontal-menu {
    padding: 32px 8px 4px;
    border-bottom: 1px solid rgb(231, 231, 231);
  }
}

.menu-item-wrapper:focus {
  outline: none;
}

.menu-item {
  position: relative;
  padding: 15px 20px;
  margin: 0 5px;
  user-select: none;
  cursor: pointer;
  border: none;

  h2 {
    font-size: 14px;
    font-weight: 500;
  }

  .active {
    font-size: 14px;
    font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.04ch;
    text-transform: none;
    color: rgb(25, 25, 25);
    margin: 0px;
    padding: 0px;
    display: block;
    font-variant-ligatures: no-common-ligatures;
  }

  .sub-border {
    position: absolute;
    bottom: 0px;
    height: 4px;
    background-color: rgb(25, 25, 25);
    width: 70%;
    border-radius: 8px 8px 0px 0px;
  }
}

.arrow-common {
  background-color: rgb(255, 255, 255);
  padding-top: 10px;
  padding-bottom: 6px;
  cursor: pointer;
}

.arrow-left {
  padding-left: 8px;
}

.arrow-right {
  padding-right: 8px;
}

//When add to item to cart, SuccessfulModal Styles
.added-to-cart-successful-modal {
  margin: 16.75rem auto !important;

  .modal-content {
    .times-cont {
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: contain;
      width: 40px;
      height: 40px;
      background-color: #f5f5f5;
      background-size: 17px;
      border: none;
      border-radius: 50%;
      outline: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      float: right;

      .fa-times {
        color: #484848;
      }
    }

    .modal-body {
      margin-top: 35px;
      padding: 1rem 5rem 5rem 5rem;

      .check-cont {
        border: 1px solid #484848;
        border-radius: 50%;
        width: 70px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;

        .fa-check {
          color: $successfullcheck;
          font-size: 38px;
        }
      }

      .message {
        margin-top: 1rem !important;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .tempvillage-cont {
    .restaurant-detail-row {
      .restaurant-details {
        .social-icons-cont {
          justify-content: flex-start;
          margin-top: 15px;
        }
      }
    }
  }

  .horizontal-menu-row {
    top: 110px;
  }
}

@media screen and (max-width: 768px) {
  .modal-dialog {
    .modal-content {
      .modal-body {
        padding: 1rem 1rem 5rem 1rem;

        .check-cont {
          border: 1px solid #484848;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;

          .fa-check {
            font-size: 32px;
          }
        }

        .message {
          font-size: 20px;
        }
      }
    }
  }
}