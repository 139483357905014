.pers-info-cont {
  padding: 25px 0;
  .settings {
    border-radius: 5px;
    border: solid 1px #e1e8ee;
    padding: 0;
    width: 100%;
    .head {
      padding: 35px 20px;
      background-color: #fff;
      border-bottom: solid 1px #e1e8ee;
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 1rem;
        font-weight: 700;
        color: #2d3436;
      }
      .edit-btn {
        font-size: 0.8125rem;
        font-weight: 500;
        letter-spacing: 2px;
        color: #73c538;
        cursor: pointer;
      }
    }
    .content {
      background-color: #fff;
      .settings-item {
        display: block;
        padding: 25px 15px;
        border-bottom: 1px solid #e1e8ee;
        .inform-box {
          .t1 {
            font-size: 1rem;
            color: #2d3436;
          }
          .t2 {
            font-size: 1rem;
            font-weight: 600;
            color: #2d3436;
            float: right;
          }
        }
      }
    }
    .form-content {
      padding: 25px 25px;
      .save-edit {
        margin-top: 20px;
        display: block;
        width: 100%;
        height: 54px;
        line-height: 54px;
        border-radius: 3px;
        border: none;
        background-color: #73c538;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 2px;
        text-align: center;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}
