@import '../../assets/sass/1-helpers/variables';

.order-card-cont {
  margin: 8px 0;

  .order-header {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    padding: 13px 8px 10px;
    border: 1px solid #e5e5e5;

    .point {
      background-color: #6dc530;
      font-weight: 700;
      background-clip: padding-box;
      padding: 1px 5px;
      color: #fff;
      font-size: 12px;
      border-radius: 4px;
    }

    .rest-name {
      color: #333;
      font-family: Open Sans, sans-serif;
      text-decoration: none;
      font-weight: 700;
      font-size: 15px;
    }

    .order-date {
      font-size: 0.7875rem;
      color: rgb(51, 51, 51);
      top: 3px;
      position: relative;
    }
  }

  .order-body {
    padding: 5px;
    background: #eff0f2;
    border-bottom: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    box-shadow: 0 1px 0 #e5e5e5;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

    .order-details {
      padding: 7px 10px;
      margin-left: -6px;
      margin-right: -6px;

      .menu-name {
        color: $primary-color;
      }

      .extra-item {
        font-size: 0.8125rem;
        margin-bottom: 0;
        margin-top: 0.5rem;
      }

      .total-price {
        font-size: 0.8125rem;
      }

      .cancel-btn {
        background-color: #dc3545;
        color: $secondary-color;
        padding: 6px 20px;
        text-align: center;
        white-space: nowrap;
        border: 0;
        background-clip: padding-box;
        transition: background 0.15s ease-in, background-color 0.15s ease-in, color 0.1s ease-in, top 0.1s ease-in;
        text-decoration: none;
        position: relative;
        top: 0;
        font-weight: 700;
        font-family: Open Sans, sans-serif;
        vertical-align: top;
        font-size: 12px;
        outline: none !important;
        border-radius: 10px;
        float: right;
      }
    }

    .order-history-detail {
      border: 1px solid #e5e5e5;
      margin: 12px 0 0;
      border-radius: 4px;
      background-color: $secondary-color;

      .pending-message {
        color: #ac000b;
        padding: 10px;
        font-size: 0.8125rem;
      }
    }
  }
}