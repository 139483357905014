@import '../../../../assets//sass/1-helpers/variables';
%total-and-operation {
  display: flex;
  justify-content: space-between;
  padding: 1px 10px;
  border-radius: 30px;
}
%d-flex-between {
  display: flex;
  justify-content: space-between;
}
.rest-menu-modal {
  .plus-minus-cont {
    @extend %total-and-operation;
    width: 20%;
    border: 1px solid $primary-color;
    span {
      color: $primary-color;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .menu-modal-header {
    border: none;
    .modal-title {
      color: $third-color;
      font-weight: bold;
    }
  }
  .menu-modal-body {
    padding: 0 16px;
    .description {
      color: $modal-description;
      font-weight: 300;
      width: 80%;
      overflow-wrap: anywhere;
    }
    b {
      font-size: 17px;
      font-weight: bold;
    }
    .address-name {
      text-decoration: underline;
      font-weight: bold;
      margin-bottom: 5px;
    }
    hr {
      margin: 10px 0;
    }
    .extra-item {
      .plus-minus-cont-extra {
        width: 70%;
      }

      .price {
        color: $modal-description;
        float: right;
      }
      .addOn-table-title {
        font-size: 15px;
        font-weight: 500;
      }
    }
    textarea {
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.15);
      outline-color: rgba(0, 0, 0, 0.15);
      resize: none;
      margin-top: 5px;
      &::placeholder {
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
  .menu-modal-footer {
    border: none;
    padding-top: 0;
    .footer-cont {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .single-picker-cont {
        padding: 0 0 0 15px;
        #exampleSelect {
          color: black;
        }
        .pickup-time-select {
          width: 100%;
          display: block;
          height: calc(1.5em + 0.75rem + 2px);
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #ced4da;
          border-radius: 0.25rem;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s;
        }
      }

      .date-picker-cont {
        padding: 0;
        .date-picker {
          display: block;
          width: 100%;
          height: calc(1.5em + 0.75rem + 2px);
          padding: 0.375rem 0.75rem;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #ced4da;
          border-radius: 0.25rem;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s;
          .ant-picker-input {
            input {
              color: black;
            }
          }
        }
      }

      .total-cont {
        @extend %total-and-operation;
        padding: 5px 10px;
        width: 50%;
        background-color: $primary-color;
        cursor: pointer;
        border: none;
        span {
          color: $secondary-color;
        }
      }
    }
  }
}

@media screen and (max-width: 426px) {
  .rest-menu-modal {
    .menu-modal-footer {
      .footer-cont {
        .single-picker-cont {
          padding: 15px 0;
          .pickup-time-select {
            width: 100%;
            padding: 0 10px;
          }
        }
        .date-picker-cont {
          padding: 15px 0;
        }
        .total-cont {
          width: 100%;
        }
      }
    }
  }
}
