.pickphoto-cont {
  .lable-title {
    font-size: 14px;
    font-weight: 400;
    color: #363636;
  }
  .star {
    color: #ff4c01;
  }
  input[type='file'] {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
  }
  .pickphoto-row {
    height: 200px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    .left {
      height: 100%;
      .company-logo {
        height: 100%;
        object-fit: cover;
      }
    }
    .right {
      padding-top: 15px;
      .error-message {
        color: red;
        margin-bottom: 8px;
      }
    }
  }
}
