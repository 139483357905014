@import '../../assets/sass/1-helpers/variables';
%middle-end-cont {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.single-rest-row {
  margin-top: 25px;
  padding: 15px 0;
  box-shadow: -1px 6px 13px 0px rgba(0, 0, 0, 0.44);
  border-radius: 10px;
  .rest-logo {
    padding: 10px;
    img {
      width: 100%;
      height: 200px;
      object-fit: contain;
    }
  }
  .middle-cont {
    @extend %middle-end-cont;
    width: 70%;
    .rest-name {
      color: $third-color;
      text-decoration: none;
      h2 {
        font-weight: bold;
      }
    }
    .phone-cont {
      i {
        font-size: 14px;
      }
      span {
        margin-left: 5px;
        font-weight: 600;
        color: $third-color;
        font-size: 0.875rem;
      }
    }
    p {
      line-height: 1.65rem;
      font-weight: 300;
      font-size: 1.125rem;
    }
    .food-type {
      color: $rest-food-type;
      font-size: 0.75rem;
      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        padding-left: 0;
        li {
          margin-left: 25px;
        }
        li:nth-child(1) {
          list-style-type: none;
          margin-left: 0;
        }
      }
    }
  }
  .end-cont {
    @extend %middle-end-cont;
    width: 100%;
    text-align: right;
    margin-left: auto;
    .comp-type {
      span {
        padding: 3px 15px;
        border-radius: 20px;
        color: $secondary-color;
      }
    }
    .work-hours {
      span {
        font-weight: bold;
      }
      p {
        font-weight: bold;
        font-size: 0.875rem;
      }
    }
    .rest-loc {
      font-size: 0.875rem;
      margin: 0;
    }
    
  }
}

@media screen and (max-width: 576px) {
  .single-rest-row {
    .rest-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 50%;
        height: 150px;
      }
    }
    .middle-cont {
      padding: 15px 0;
      width: 100%;
    }
    .end-cont {
      padding: 15px 15px 15px 15px;
      text-align: left;
      .comp-type {
        span {
          float: none;
        }
      }
      .work-hours {
        margin-top: 10px;
      }
      .icons-list {
        margin-top: 10px;
      }
    }
  }
}

@media screen and (max-width: 996px) {
  .single-rest-row {
    .end-cont {
      width: 100%;
      .comp-type {
        span {
          padding: 3px 6px;
          display: inline-block;
          margin-top: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .single-rest-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
    .end-cont {
      padding: 0;
    }
  }
}
