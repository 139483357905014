@import '../../assets/sass/1-helpers/variables';
%craw-sign-btn {
  border: none;
  background-color: $primary-color;
  color: $secondary-color;
  padding: 10px 15px;
  margin-top: 10px;
}

.top-container {
  width: 100%;
  padding-bottom: 50px;

  .content {
    padding: 20px 50px;
    .searchbox-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 5%;
    }
    .searchbox-container > div {
      width: 55%;

      .text-container {
        margin-bottom: 35px;
        h1 {
          font-family: $h1-title;
          font-size: 2.625rem;
          font-weight: 600;
        }
        h4 {
          color: rgba($color: $third-color, $alpha: 0.7);
          margin-top: 25px;
        }
        p {
          line-height: 1.8rem;
          margin-top: 15px;
          width: 70%;
        }
      }
      a {
        text-decoration: none;
        span {
          cursor: pointer;
          background-color: $primary-color;
          color: $secondary-color;
          padding: 10px 15px;
          text-align: center;
          font-size: 0.9rem;
        }
      }
    }

    .plate-container {
      text-align: center;
      padding-top: 30px;
      display: flex;

      .carousel-cont {
        img {
          width: 60%;
        }

        .carousel {
          .slide {
            background: none;
          }
        }
        .legend {
          visibility: hidden;
        }
        .carousel.carousel-slider .control-arrow {
          visibility: hidden;
        }
        .carousel-status {
          visibility: hidden;
        }
      }
    }
  }

  .craw-container {
    margin-top: 100px;
    h2 {
      padding: 50px 0;
      font-weight: bold;
    }
    .craw-pics-row {
      padding-left: 5%;
      .left-pics {
        padding: 0;

        #craw1 {
          width: 40%;
          img {
            width: 100%;
            height: min-content;
          }
        }
        #craw2 {
          width: 60%;
          img {
            width: 100%;
            height: min-content;
          }
        }
        #craw5 {
          width: 40%;
          img {
            width: 100%;
            height: min-content;
          }
        }
        #craw4 {
          width: 60%;
          img {
            width: 100%;
            height: min-content;
          }
        }
      }
      .right-pic {
        padding: 0;
        #craw3 {
          width: 100%;
          img {
            width: 100%;
            height: min-content;
          }
        }
      }
      .craw-pic-cont {
        display: inline-block;
        position: relative;
        color: white;
        width: 100%;
        cursor: pointer;
        .centered {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          visibility: hidden;
          font-weight: bold;
          font-size: 1.375rem;
        }
      }
      .craw-btn-col {
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: black;
        .btn-cont {
          width: 80%;
          text-align: left;
          h4 {
            font-weight: bold;
          }
          button {
            padding: 10px 15px;
            @extend %craw-sign-btn;
            font-size: 0.875rem;
            width: 100%;
          }
        }
      }
    }
  }

  .add-rest-container {
    margin: 100px 0;

    .left-text {
      display: flex;
      justify-content: center;
      align-items: center;
      .text-cont {
        width: 50%;

        h2 {
          font-weight: bold;
          line-height: 2.8rem;
        }
        p {
          line-height: 1.7rem;
          width: 80%;
        }
        button {
          @extend %craw-sign-btn;
          padding: 10px 25px;
        }
      }
    }

    .right-img {
      img {
        width: 90%;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .top-container {
    .content {
      padding: 20px 15px;
      .searchbox-container {
        padding-top: 10px;
      }
      .searchbox-container > div {
        width: 80%;
        span {
          width: 100%;
        }
      }
    }
    .craw-container {
      .craw-pics-row {
        padding: 0 5%;
        .craw-pic-cont {
          .centered {
            visibility: visible;
          }
        }
        .craw-btn-col {
          .btn-cont {
            padding-top: 25px;
          }
        }
      }
    }
    .add-rest-container {
      .add-rest-row {
        flex-direction: column-reverse;
        .left-text {
          .text-cont {
            width: 80%;
            padding-top: 20px;
            button {
              margin: 15px 0;
              width: 100%;
            }
          }
        }
        .right-img {
          display: flex;
          justify-content: center;
          align-items: center;
          picture {
            width: 80%;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 1024px) {
  .top-container {
    .content {
      .searchbox-container > div {
        margin-top: 5%;
        a {
          span {
            float: right;
          }
        }
      }
    }
    .craw-container {
      .craw-pics-row {
        .craw-pic-cont {
          .centered {
            visibility: visible;
          }
        }
      }
    }

    .add-rest-container {
      .add-rest-row {
        .left-text {
          .text-cont {
            width: 90%;
            button {
              float: right;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1025px) {
  .top-container {
    .craw-container {
      .craw-pics-row {
        .craw-pic-cont:hover {
          .centered {
            visibility: visible;
            background: rgba($color: $third-color, $alpha: 0.5);
          }
        }
      }
    }
  }
}
