%general-props {
    margin: 0;
    padding: 0;
  }
  %title-props {
    color: #000 !important;
    text-shadow: 0px 0px 0 #000, 0px 0px 0 #000, 0px 0px 0 #000, 0px 0px 0 #000;
  }
  .cookie-policy-cont {
    .title-row {
      padding-top: 48px;
      padding-bottom: 12px;
      text-align: center;
      .title-cont {
        font-weight: 500;
        font-size: 1.4rem;
        color: rgba($color: #000000, $alpha: 0.8);
      }
      div:nth-child(2) {
        font-size: 0.8125rem;
      }
    }
    .content-row {
      padding-top: 12px;
      .content-col {
        .title {
          font-size: 1.5rem;
          padding: 14px 0;
          b {
            @extend %title-props;
          }
        }
        h2 {
          @extend %general-props;
          font-size: 1.125rem;
          font-weight: 700;
          b {
            @extend %title-props;
          }
        }
        p {
          @extend %general-props;
          padding: 8px 0;
          font-size: 0.8125rem;
        }
        ul{
            li{
                font-size: 0.8125rem;  
            }
        }
      }
    }
  }
  