.account-cont {
  .welcome {
    background-color: #fff;
    padding: 40px 20px 30px 20px;
    border-radius: 5px;
    margin-bottom: 40px;
    .user-pic {
      border-radius: 50%;
    }
    .title {
      font-size: 1rem;
      font-weight: 700;
      color: #062819;
      margin-top: 25px;
      .given-name {
        color: #73c538;
      }
    }
    .text {
      margin-top: 20px;
      font-size: 14px;
      line-height: 1.43;
      color: #062819;
    }
  }
  .non-order {
    background-color: #fff;
    padding: 10px 25px;
    border-radius: 3px;
    margin-bottom: 30px;
    .title {
      font-size: 14px;
      line-height: 1.43;
      color: #062819;
      display: block;
      margin-bottom: 30px;
    }
    .others {
      .continue {
        width: 204px;
        height: 54px;
        line-height: 54px;
        border-radius: 3px;
        background-color: #73c538;
        display: inline-block;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 2px;
        text-align: center;
        color: #fff;
        margin-right: 30px;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 426px) {
  .account-cont {
    .non-order {
      .others {
        .continue {
          width: 100%;
        }
      }
    }
  }
}
