@import '../../assets/sass/1-helpers/variables';

%before-and-after {
  display: block;
  -webkit-box-flex: 1;
  flex-grow: 1;
  height: 1px;
  background-color: rgb(196, 196, 196);
  content: '';
}
%already-have {
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0ch;
  text-transform: none;
}
.sign-in-cont {
  padding-top: 50px;
  padding-bottom: 50px;
  .sign-in-row {
    margin: 0 3rem;
    .sign-in-col {
      padding: 36px;
      margin: 0 auto;
      box-shadow: -1px 6px 13px 0px rgba(0, 0, 0, 0.44);
      border-radius: 8px;
      .already-title {
        @extend %already-have;
        color: $registration-text;
        span {
          @extend %already-have;
          color: $primary-color;
          cursor: pointer;
        }
      }
      h3 {
        text-align: center;
        padding: 8px 0 12px 0;
        font-weight: bold;
      }
      .sign-in-divider {
        display: flex;
        -webkit-box-flex: 0;
        flex-grow: 0;
        -webkit-box-align: center;
        align-items: center;
        color: $registration-divider;
        margin: 32px 0 16px 0;
        &:before {
          @extend %before-and-after;
          margin-right: 8px;
        }
        &:after {
          @extend %before-and-after;
          margin-left: 8px;
        }
        span {
          font-size: 14px;
          font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, 'Segoe UI',
            Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
            'Segoe UI Emoji', 'Segoe UI Symbol';
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0ch;
          text-transform: none;
          color: $registration-text;
          margin: 0px;
          padding: 0px;
          display: block;
          font-variant-ligatures: no-common-ligatures;
        }
      }
      .signup-forgot {
        color: $registration-text;
        font-weight: 600;
        cursor: pointer;
      }

      .signin-btn {
        width: 100%;
        margin-top: 16px;
        font-weight: 700;
        background-color: $primary-color;
        border: none;
      }
      .auth-err-message{
        text-align: center;
        padding-top: 20px;
        p{
          color: red;
          font-weight: 600;
        }
      }
    }
  }
}

@media screen and (max-width: 426px) {
  .sign-in-cont {
    .sign-in-row {
      margin: 0;
      .sign-in-col {
        box-shadow: none;
        padding: 0 4px;
      }
    }
  }
}
