@import '../../assets/sass/1-helpers/variables';

.order-page-cont {
  h3 {
    font-weight: 700;
  }
  hr {
    margin: 5px 0;
  }
  .confirm-cont {
    .confirm-row {
      .right {
        .address-name {
          text-decoration: underline;
          font-weight: bold;
          margin-bottom: 5px;
        }
        padding: 30px 50px 0 50px;
        h6 {
          clear: both;
        }

        h6 {
          font-weight: bold;
          font-size: 1.125rem;
        }
        .panel-collapse {
          max-height: 500px;
          overflow: hidden;
          transition: max-height 0.5s ease;
        }
        .panel-close {
          max-height: 0;
        }
        .form-cont {
          .inform-box {
            margin-bottom: 5px;
            .t1 {
              font-size: 1rem;
              color: #2d3436;
            }
            .t2 {
              font-size: 1rem;
              font-weight: 600;
              color: #2d3436;
              float: right;
            }
          }
        }
        p {
          font-size: 0.85rem;
        }

        .flatpickr-input {
          border: 1px solid #ced4da;
          padding: 0.375rem 0.75rem;
          outline: none;
        }
      }
      .left {
        padding: 30px 100px 0 15px;

        h5 {
          font-weight: 700;
        }
        .rest-type {
          padding: 3px 25px;
          border-radius: 20px;
          color: $secondary-color;
        }
        .menu-item-cont {
          padding: 25px 0;
          .menu-item-row {
            padding: 10px 0;
            .count {
              font-weight: bold;
            }
            .name {
              font-weight: bold;
            }
            .extra-item {
              font-size: 0.75rem;
            }
            .fa-times {
              font-size: 18px;
              color: rgba(0, 0, 0, 0.33);
            }
            .instructions-col {
              overflow-wrap: anywhere;
              .instructions {
                color: #929292;
                font-weight: 600;
              }
            }
          }
          .sub-total-cont {
            margin-top: 35px;
            color: #929292;
          }
        }
      }
    }
    .order-btn {
      padding: 5px 45px;
      color: $secondary-color;
      font-weight: bold;
      font-size: 1rem;
      background: #f7721b;
      border-radius: 30px;
      border: none;
      outline: none;
    }
  }
  .react-time-picker_inputGroup_input:invalid {
    background: none;
  }
}
@media screen and (max-width: 576px) {
  .order-page-cont {
    .confirm-cont {
      .confirm-row {
        .left {
          padding-right: 15px;
        }
        .right {
          padding-left: 15px;
          padding-right: 15px;
          .menu-item-cont {
            .menu-item-row {
              padding: 5px;
            }
          }
        }
      }
    }
    .order-btn {
      border-radius: 0;
      width: 100%;
    }
  }
}
@media screen and (max-width: 768px) and (min-width: 577px) {
  .order-page-cont {
    .confirm-cont {
      .confirm-row {
        .right {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}
