.temp-village-modal-cont {
    position: relative;
    background-color: black;
    min-height: 0px;
    max-height: calc(100vh - 32px);
    max-width: 560px;
    width: calc(100vw - 32px);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .temp-village-modal-header {
        padding: 4px;
    }

    .temp-village-modal-body {
        max-height: calc(100vh - 210px);
        overflow-y: auto;
        padding: 1rem 1rem;

        .name-description-cont {
            max-width: 100%;
            padding: 0px 0 16px;

            .name {
                font-size: 32px;
                font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
                font-weight: 700;
                line-height: 40px;
                letter-spacing: -0.04ch;
                text-transform: none;
                color: #191919;
                text-align: left;
                margin: 0px;
                padding: 0px;
                display: block, ;
                font-variant-ligatures: no-common-ligatures;
            }

            .description {
                font-size: 16px;
                font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0ch;
                text-transform: none;
                color: #767676;
                text-align: left;
                margin: 0px;
                padding: 0px;
                display: block;
                font-variant-ligatures: no-common-ligatures;
            }
        }

        .img-cont {
            max-width: 100%;
            padding: 8px 0;
            margin: 20px 0px;

            img {
                height: 100%;
                width: 100%;
            }
        }

        .add-on-group {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 15px;

            div {
                max-width: 100%;

                .add-on-group-name {
                    font-size: 16px;
                    font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
                    font-weight: 700;
                    line-height: 22px;
                    letter-spacing: -0.04ch;
                    text-transform: none;
                    color: #191919;
                    margin: 0px;
                    padding: 0px;
                    display: block;
                    font-variant-ligatures: no-common-ligatures;
                }

                .add-on-limit {
                    font-size: 12px;
                    font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
                    font-weight: 500;
                    line-height: 18px;
                    letter-spacing: 0ch;
                    text-transform: none;
                    color: #767676;
                    margin: 0px;
                    padding: 0px;
                    display: block;
                    font-variant-ligatures: no-common-ligatures;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .optional-required-cont {
                display: flex;
                align-items: center;
                padding: 4px;
                border-radius: 4px;

                .optional-required {
                    font-size: 14px;
                    font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: 0ch;
                    text-transform: none;
                    color: #767676;
                    margin: 0px;
                    padding: 0px;
                    display: block;
                    font-variant-ligatures: no-common-ligatures;
                }
            }
        }

    }

    .temp-village-modal-footer {
        min-width: 192px;

        .minus-plus {
            background-color: rgb(235, 23, 0);
            border-radius: 50%;
            width: 39px;
            border-color: rgb(235, 23, 0);
            box-shadow: transparent 0px 0px 0px 1px inset;
            justify-content: center;
        }

        .menu-count {
            border-style: solid;
            display: inline-block;
            font-weight: 700;
            color: #212529;
            text-align: center;
            vertical-align: middle;
            background-color: transparent;
            border: 1px solid transparent;
            padding: .375rem .75rem;
            font-size: 1rem;
            line-height: 1.5;
            border-radius: .25rem;
            transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        }

        .total-price {
            position: relative;
            max-width: 108%;
            margin: 0px;
            padding: 0px;
            display: flex;
            min-height: 40px;
            width: 100%;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            border-radius: 4px;
            border: none;
            cursor: pointer;
            transition: background-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
            user-select: none;
            text-decoration: none;
            text-align: center;
            background-color: rgb(235, 23, 0) !important;
            box-shadow: transparent 0px 0px 0px 1px inset;
        }

    }
}

.choice-checkbox {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    -webkit-box-align: center;
    width: 28px;
    height: 16px;
    border: 2px solid #191919;
    border-radius: 4px;
    background-color: #FFFFFF;
    outline: none;
    transition: all 0.2s ease-in-out 0s;
}

.choice-name {
    font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0ch;
    text-transform: none;
    color: 191919;
    margin: 10px;
    padding: 0px;
    font-variant-ligatures: no-common-ligatures;
}

.choice-row {
    justify-content: space-between;
}

.choice-price {
    text-align: right;
}

.choice-hr {
    width: 107%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0 !important;
    position: relative;
    left: -15px;
}

.inst-cont {
    display: flex;
    flex-direction: column;
    align-items: center;

    textarea {
        width: 100%;
        margin-top: 3px;
    }
}