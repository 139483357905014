.standard-input {
  .lable-title {
    font-size: 14px;
    font-weight: 400;
    color: #363636;
  }
  .star {
    color: #ff4c01;
  }
  .error-message {
    color: red;
    margin-bottom: 8px;
  }
}
