@import '../../assets/sass/1-helpers/variables';

%shopping-cart {
  align-items: center;
  cursor: pointer;
  margin-left: 50px;

  i {
    color: $secondary-color;
  }

  span {
    margin-left: 10px;
    color: $secondary-color;
  }
}

header {
  .main-navbar {
    padding: 10px 50px;
    background-image: url('../../assets/images/navbarBackground.jpg');
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;

    /* Resize the background image to cover the entire container */
    .navbar-logo {
      //logo's a tag
      padding-top: 0;
      padding-bottom: 0;

      img {
        width: 86px;
        height: 86px;
      }
    }

    .menu-icon {
      color: $secondary-color !important;
      border: none;
    }

    .search-main-cont {
      display: flex;
      justify-content: flex-end;
      width: 60%;

      .search-bar-cont {
        width: 60%;
        display: flex;
        align-items: center;
        padding-left: 10px;
        margin-left: 18px;
        height: 40px;
        background-color: rgba($color: $secondary-color, $alpha: 0.6);
        border-radius: 30px;
        border: 1px solid transparent;

        a {
          color: $third-color;
          padding: 0 10px;
          text-decoration: none;
        }

        input {
          width: 100%;
          border: none;
          background: none;
          outline: none;
        }
      }
    }

    .nav-collapse {
      width: 30%;
      justify-content: flex-end;

      .nav-list {
        li {
          margin-left: 10px;
          font-size: 1rem;
          color: $third-color;

          a {
            color: $secondary-color;
            font-weight: bold;
          }
        }

        .sign-in-up-btn {
          &:hover {
            padding: 5px 10px;
            box-shadow: -1px 6px 13px 0px rgba(0, 0, 0, 0.44);
            color: $secondary-color;
            border-radius: 30px;
          }
        }
      }

      .theme-toggle {
        display: none;

        i {
          padding: 8px;
          margin-left: 50px;

          font-size: 24px;
        }
      }
    }

    .shopping-cart-cont {
      display: flex;
      @extend %shopping-cart;
    }

    .shopping-cart-cont-extra {
      @extend %shopping-cart;
      display: none;
    }
  }
}

@media screen and (max-width: 575px) {
  header {
    .main-navbar {
      padding: 10px;

      a {
        img {
          height: 56px;
          width: 56px;
        }
      }

      .shopping-cart-cont-extra {
        margin-left: 0 !important;
      }

      .search-main-cont {
        .search-bar-cont {
          width: 100%;
          margin-left: 0;

          input {
            font-size: 0.8rem;
          }
        }
      }

      .menu-icon {
        margin-right: 10px;
      }

      .nav-collapse {
        .nav-list {
          margin-right: 0;

          a {
            color: rgba($color: $third-color, $alpha: 0.5);
          }

          li {
            align-items: center;
            margin-left: 0;
          }
        }
      }

      .shopping-cart-cont {
        margin-top: 10px;
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 1024px) {
  header {
    .main-navbar {
      padding: 10px 10px;

      .search-main-cont {
        .search-bar-cont {
          width: 100%;
        }
      }

      .nav-collapse {
        justify-content: flex-end;
      }
    }
  }
}

@media (max-width: 768px) {
  header {
    .main-navbar {
      .nav-collapse {
        .theme-toggle {
          i {
            margin-left: 0;
          }
        }
      }

      .shopping-cart-cont {
        display: none;
      }

      .shopping-cart-cont-extra {
        display: flex;
        margin-left: 15px;
      }
    }
  }
}