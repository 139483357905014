.mainRow {
  margin-top: 50px;
}

.colLeft {
  padding-bottom: 16px;
}

.insideRow {
  padding: 30px 0px;
  border-bottom: 1px solid rgb(214, 214, 214);
}

.h2-1 {
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0.1ch;
  text-transform: uppercase;
  color: rgb(25, 25, 25);
  margin: 0px;
  padding: 0px;
  display: block;
  font-variant-ligatures: no-common-ligatures;
}

.addOnsStyle {
  font-size: 14px;
  font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0ch;
  text-transform: none;
  color: rgb(118, 118, 118);
  margin: 0px;
  padding: 0px;
  display: block;
  font-variant-ligatures: no-common-ligatures;
}

.product-name {
  font-size: 16px;
  font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0ch;
  text-transform: none;
  color: rgb(25, 25, 25);
  margin: 0px;
  padding: 0px;
  display: block;
  font-variant-ligatures: no-common-ligatures;
}

.price-span {
  font-size: 16px;
  font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0ch;
  text-transform: none;
  color: rgb(25, 25, 25);
  margin: 0px;
  padding: 0px;
  display: block;
  font-variant-ligatures: no-common-ligatures;
}

.price-names {
  font-size: 16px;
  font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0ch;
  text-transform: none;
  color: rgb(25, 25, 25);
  margin: 0px;
  padding: 0px;
  display: block;
  font-variant-ligatures: no-common-ligatures;
}

.heading-name {
  font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0ch;
  color: rgb(25, 25, 25);
  margin: 0px;
  padding: 0px;
  display: block;
  font-variant-ligatures: no-common-ligatures;
}

.heading-text {
  font-size: 16px;
  font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0ch;
  text-transform: none;
  color: rgb(118, 118, 118);
  margin: 0px;
  padding: 0px;
  display: initial;
  font-variant-ligatures: no-common-ligatures;
}

.no-option-darkgray {
  color: darkgray;
  cursor: not-allowed;
}

.not-allowed {
  cursor: not-allowed;
}

.panel-collapse {
  max-height: 500px;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.panel-close {
  max-height: 0;
}

.rest-logo-row {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px;
}

.rest-logo-img {
  width: 55px;
  height: 55px;
  border-radius: 4px;
  margin-right: 16px;
  border: 1px solid rgb(214, 214, 214);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.rest-name-heading {
  font-size: 14px;
  font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0ch;
  text-transform: none;
  color: rgb(118, 118, 118);
  margin: 0px;
  padding: 0px;
  display: block;
  font-variant-ligatures: no-common-ligatures;
}

.rest-name {
  font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0ch;
  color: rgb(25, 25, 25);
  margin: 0px;
  padding: 0px;
  display: block;
  font-variant-ligatures: no-common-ligatures;
}

/* Stripe */

/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.sr-field-error {
  color: red;
  font-size: 0.875rem;
  margin-top: 5px;
}

.order-btn {
  padding: 5px 80px;
  color: #ffffff;
  font-weight: bold;
  font-size: 1rem;
  background: #f7721b;
  border-radius: 30px;
  border: none;
  outline: none;
}

@media (max-width: 576px) {

  .mainRow {
    flex-direction: column-reverse !important;
  }

  .colRight {
    padding-bottom: 30px;
  }

  #contact-row {
    border-top: 1px solid rgb(214, 214, 214);
  }

  .contact-hr {
    width: 60%;
    position: relative;
    right: 3%;
  }

}