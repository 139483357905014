.successful-modal-completed-order {
  .modal-header {
    border: none;
  }

  .successful-modal-body {
    padding: 24px 16px 48px 16px;
    color: black;
    text-align: center;

    b {
      padding-top: 10px;
      font-size: 1.4rem;
    }

    p {
      width: 90%;
      padding-top: 10px;
      margin: 0 auto;
    }
  }
}