.category-menu-card {
    text-align: left;
    border: 1px solid rgb(231, 231, 231);
    border-radius: 4px;
    cursor: pointer;

    .menu-name {
        font-size: 16px;
        font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-weight: 700;
        line-height: 22px;
        letter-spacing: -0.04ch;
        text-transform: none;
        color: rgb(25, 25, 25);
        margin: 0px;
        padding: 0px;
        display: block;
        font-variant-ligatures: no-common-ligatures;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .description {
        font-size: 14px;
        font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0ch;
        text-transform: none;
        color: rgb(118, 118, 118);
        margin: 0px;
        padding: 0px;
        font-variant-ligatures: no-common-ligatures;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height: 40px;
        white-space: normal;
        margin: 4px 0;
    }

    .price-cont {
        max-width: 100%;
        display: flex;
        -webkit-box-align: stretch;
        align-items: stretch;
        -webkit-box-pack: start;
        justify-content: flex-start;
        margin-top: 4px;

        .price {

            font-size: 14px;
            font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0ch;
            text-transform: none;
            color: rgb(73, 73, 73);
            margin: 0px;
            padding: 0px;
            display: block;
            font-variant-ligatures: no-common-ligatures;
        }
    }

    .menu-img {
        width: 100%;
        height: 141px;
        object-fit: cover;
    }
}