@import '../../assets/sass/1-helpers/variables';

.icons-list {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    list-style: none;
    margin: 0;

    li {
        a {
            margin-left: 10px;
            color: $third-color;
        }

        .disactive-icon {
            margin-left: 10px;
        }

        .disactive-icon:hover {
            color: rgba($color: gray, $alpha: 0.3);
        }

        #active-website:hover {
            color: #0278ae;
        }

        #active-insta:hover {
            color: #e1306c;
        }

        #active-twitter:hover {
            color: #1da1f2;
        }

        #active-facebook:hover {
            color: #4267b2;
        }
        #active-phone:hover {
            color: green;
        }
    }
}

@media screen and (max-width: 576px) {
    .icons-list {
        margin-top: 10px;
    }
}