@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');

//Color Variables

$primary-color: #ff4c01;
$secondary-color: #ffffff;
$third-color: black;
$nav-bg-color: #EF8657;
$footer-hr: rgba(253, 182, 157, 0.55);
$social-icon-bg:#9aadca;
$insta-icon: #e4405f;
$twitter-icon: #55acee;
$facebook-icon: #3b5999;
$world-icon: gray;
$terms-text: #523737;
$rest-food-type: #747474;
$pickup: #148f00;
$rest-border: #ff4c01;
$operation: #3fa32e;
$menu-card-border: rgba(136, 136, 136, 0.54);
$modal-description: #747474;
$footer-bg: #06080c;
$registration-divider: rgb(73, 73, 73);
$registration-text: rgb(118, 118, 118);
$successfullcheck: #17be17;
//Font families
$h1-title: 'Source Sans Pro', sans-serif;

//Font-sizes
