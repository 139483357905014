@import '../../assets/sass/1-helpers/variables';

footer {
  background-color: $footer-bg;
  height: 105px;
  width: 100%;

  .footer-container {
    max-width: 90vw;

    .footer-row {
      padding: 10px 0;

      .footer-left {
        .terms-cont {
          a {
            color: $social-icon-bg !important;
            font-size: 0.875rem;
          }
        }
      }

      .footer-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .icon-container {
          display: flex;
          justify-content: flex-end;

          .icons-list {
            display: flex;
            padding: 0;
            margin: 0;
            list-style: none;

            li {
              margin-left: 10px;
            }

            i {
              background-color: $social-icon-bg;
              color: $footer-bg;
              border-radius: 50%;
              padding: 5px;
              transition: 0.2s;
            }

            .fa-facebook-f {
              padding: 5px 8px;
            }

            .fa-instagram {
              padding: 5px 6px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  footer {
    height: 140px;

    .footer-container {
      .footer-row {
        flex-direction: column;
        align-items: center;

        .footer-left {
          text-align: center;
        }

        .footer-right {
          padding-top: 15px;
          justify-content: center;

          .icon-container {
            .terms-cont {
              font-size: 0.9rem;
            }

            .icons-list {
              margin-right: 20px;
            }
          }
        }
      }

    }
  }
}