@import "../../assets/sass/1-helpers/variables";

    .fa-times{
        font-size: 24px;
        cursor: pointer;
    }
    .cart-img{ 
        margin: 0 auto;

    }
    .cart-title{
       display: flex !important;
       flex-direction: column;
       justify-content: center;
       align-items: center;
       padding: 25px 0 50px 0;
       span:nth-child(1){
           font-weight: bold;
           font-size: 1.5rem;
       }
       span:nth-child(2){
           font-size: 1.25rem;
       }
    }
    .cart-empty-text{
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0ch;
        text-transform: none;
        color: rgb(118, 118, 118);
        text-align: center;
        margin: 0px;
        padding: 0px;
        display: block;
        font-variant-ligatures: no-common-ligatures;
    }

    .checkout-total{
        display: flex !important;
        justify-content: space-between;
        width: 55%;
        border-radius: 30px;
        background-color: $pickup;
        color: $secondary-color;
        padding: 6px 10px;
        margin: 25px auto 0 auto;
        cursor: pointer;
        font-size: 1rem;
        
    }


    @media screen and (max-width:576px){
        .bm-menu-wrap{
            width: 100% !important;
        }
    }

    @media screen and (min-width:577px) and (max-width:1024px){
        .bm-menu-wrap{
            width: 60% !important;
        }
    }