
    .category-page-cont{
       padding-bottom: 50px;
        .images-row{
            padding: 50px 50px 50px 50px !important;
           .category-img{
               width: 95%;
               height: 200px;
               border-radius: 15px;
           }
        }
    }


@media screen and (min-width:1441px){
    
    .category-page-cont{
        .images-row{
           .category-img{
            width: 85%;  
            height: 280px;
           }
        }
    }
    
}